<template>
  <div class="row m-0 abs">
    <div class="col-12">
      <div class="row">
        <div class="col-12 bold smallheading my-4">
          Add Gold & Silver
        </div>
        <div class="col-12 col-md-6 mb-2">
          <TextField
            type="text"
            color="gold"
            placeholder="Description"
            v-model="commodity.description"
            id="description"
            label="Description:"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <SelectAll
            v-model="commodity.type"
            id="type"
            :options="commodityTypes"
            displayValue="description"
            placeholder="Gold/Silver/Kruger Rand"
            color="gold"
            label="Select Commodity:"
            width="full"
            :goUp="true"
          />
        </div>
        <div class="col-12 col-md-6 mb-2" v-if="commodity.type.value === 'GOLD_JEWELLERY' || commodity.type.value === 'GOLD_KRUGERRAND_COIN'">
          <SelectAll
            v-model="commodity.proportion"
            id="proportion"
            :options="proportions"
            displayValue="description"
            placeholder="Proportion"
            color="gold"
            label="Select Proportion:"
            width="full"
            :goUp="true"
          />
        </div>
        <div class="col-12 col-md-6 mb-2" v-if="commodity.type.value && commodity.type.value !== 'GOLD_KRUGERRAND_COIN'">
          <TextField
            type="number"
            color="gold"
            placeholder="Weight in grams"
            v-model="commodity.weightInGrams"
            id="weight"
            label="Weight in grams:"
          />
        </div>
        <div class="col-12 col-md-6 mb-2" v-else-if="commodity.type.value && commodity.type.value === 'GOLD_KRUGERRAND_COIN'">
          <TextField
            type="number"
            color="gold"
            placeholder="Quantity"
            v-model="commodity.quantity"
            id="quantity"
            label="Quantity:"
          />
        </div>
        <div class="col-12 bold smallheading my-4">
          Total: {{defaultCurrency}}{{calculatedAmount}}
        </div>
        <div class="col-12 my-4" v-if="isEdit">
          <Button color="green" btnText="Update" icon="arrow" @buttonClicked="update" class="mb-1" :disabled="calculatedAmount < 0.01" >
            <IconArrowForward color="gold" />
          </Button>
          <Button color="transparent" btnText="Cancel" @buttonClicked="cancel" class="mb-1 ms-3" >
          </Button>
        </div>
        <div class="col-12 my-4" v-else>
          <Button color="green" btnText="Save" icon="arrow" @buttonClicked="save" class="mb-1" :disabled="calculatedAmount < 0.01" >
            <IconArrowForward color="gold" />
          </Button>
        </div>
      </div>
      <div class="row" v-if="commodities.length > 0">
        <div class="col-12 bold smallheading my-4">
          Commodities
        </div>
      </div>
      <div class="row py-2 border-bottom " v-for="(asset, key) in commodities" :key="key">
        <div class="col-12">
          <div class="row d-none d-md-flex align-items-center">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{asset.description}}
                </div>
                <div class="col-12 font12">
                  {{displayDescription(asset.commodityType)}}<span class="ms-1" v-if="asset.commodityType === 'GOLD_JEWELLERY' || asset.commodityType === 'GOLD_KRUGERRAND_COIN'">{{ displayDescription(asset.proportion) }}</span>
                </div>
                <div class="col-12 font12">
                  {{ (asset.commodityType === 'GOLD_KRUGERRAND_COIN' ? `${asset.quantity} units` : `${asset.weightInGrams}g` ) }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" btnText="EDIT" icon="arrow" size="xsmall" @buttonClicked="editIndividual(key, asset)">
                <IconEdit size="size16" />
              </Button>
            </div>
            <div class="col-auto text-end bold">
              {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="small" @buttonClicked="showDelete(key, asset)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
          <div class="row d-md-none">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{asset.description}}
                </div>
                <div class="col-12 font12">
                  {{displayDescription(asset.commodityType)}}<span class="ms-1" v-if="asset.commodityType === 'GOLD_JEWELLERY' || asset.commodityType === 'GOLD_KRUGERRAND_COIN'">{{ displayDescription(asset.proportion) }}</span>
                </div>
                <div class="col-12 font12">
                  {{ (asset.commodityType === 'GOLD_KRUGERRAND_COIN' ? `${asset.quantity} units` : `${asset.weightInGrams}g` ) }}
                </div>
                <div class="col-12 bold mt-2">
                  {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="editIndividual(key, asset)">
                <IconEdit />
              </Button>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="showDelete(key, asset)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Alert v-if="isDelete" title="Are you sure you want to delete this commodity?" :message="commodity.description" @close="closeDelete" button1="Delete" button2="Cancel" align="center" @doAction2="closeDelete" @doAction1="deleteItem" key="alert"></Alert>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Alert: defineAsyncComponent(() => import('../../../components/Alert.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    'ios-trash-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-trash.vue'))
  },
  name: 'AddGold',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      commodity: {
        description: '',
        type: {},
        quantity: null,
        weightInGrams: null,
        proportion: {},
        commodityTypes: '',
        total: 0
      },
      isEdit: false,
      isDelete: false,
      currentKey: null,
      commodityTypes: [
        {
          value: 'GOLD_JEWELLERY',
          description: 'Gold Jewellery'
        }, {
          value: 'GOLD_KRUGERRAND_COIN',
          description: 'Kruger Rand Coins'
        }, {
          value: 'OTHER_GOLD_COINS',
          description: 'Other Gold Coins'
        }, {
          value: 'SILVER_JEWELLERY',
          description: 'Silver Jewellery'
        }, {
          value: 'OTHER_SILVER_COINS',
          description: 'Other Silver Coins'
        }
      ],
      proportionTypes: [{
        value: 'TWENTY_FOUR_KARAT',
        description: '24ct',
        commodityTypes: ['GOLD_JEWELLERY']
      }, {
        value: 'TWENTY_TWO_KARAT',
        description: '22ct',
        commodityTypes: ['GOLD_JEWELLERY', 'OTHER_GOLD_COINS']
      }, {
        value: 'TWENTY_ONE_KARAT',
        description: '21ct',
        commodityTypes: ['GOLD_JEWELLERY']
      }, {
        value: 'EIGHTEEN_KARAT',
        description: '18ct',
        commodityTypes: ['GOLD_JEWELLERY']
      }, {
        value: 'FOURTEEN_KARAT',
        description: '14ct',
        commodityTypes: ['GOLD_JEWELLERY']
      }, {
        value: 'ONE_OUNCE_KRUGERRAND',
        description: '1oz',
        commodityTypes: ['GOLD_KRUGERRAND_COIN']
      }, {
        value: 'HALF_OUNCE_KRUGERRAND',
        description: '1/2oz',
        commodityTypes: ['GOLD_KRUGERRAND_COIN']
      }, {
        value: 'QUARTER_OUNCE_KRUGERRAND',
        description: '1/4oz',
        commodityTypes: ['GOLD_KRUGERRAND_COIN']
      }, {
        value: 'ONE_TENTH_OUNCE_KRUGERRAND',
        description: '1/10oz',
        commodityTypes: ['GOLD_KRUGERRAND_COIN']
      }, {
        value: 'OTHER_GOLD_COINS',
        description: 'Other Gold Coins',
        commodityTypes: []
      }, {
        value: 'ONE',
        description: 'One',
        commodityTypes: []
      }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'exchangeRate',
      'goldRatePerOunce',
      'goldRatePerGram',
      'silverRatePerGram',
      'commodities'
    ]),
    TWENTY_FOUR_KARAT () {
      return (this.goldRatePerGram).toFixed(2)
    },
    TWENTY_TWO_KARAT () {
      return (this.goldRatePerGram * 22 / 24).toFixed(2)
    },
    TWENTY_ONE_KARAT () {
      return (this.goldRatePerGram * 21 / 24).toFixed(2)
    },
    EIGHTEEN_KARAT () {
      return (this.goldRatePerGram * 18 / 24).toFixed(2)
    },
    FOURTEEN_KARAT () {
      return (this.goldRatePerGram * 14 / 24).toFixed(2)
    },
    ONE_OUNCE_KRUGERRAND () {
      return (this.goldRatePerOunce).toFixed(2)
    },
    HALF_OUNCE_KRUGERRAND () {
      return (this.goldRatePerOunce / 2).toFixed(2)
    },
    QUARTER_OUNCE_KRUGERRAND () {
      return (this.goldRatePerOunce / 4).toFixed(2)
    },
    ONE_TENTH_OUNCE_KRUGERRAND () {
      return (this.goldRatePerOunce / 10).toFixed(2)
    },
    DisplayTWENTY_FOUR_KARAT () {
      return parseFloat(Math.round(this.goldRatePerGram * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    DisplayTWENTY_TWO_KARAT () {
      return parseFloat(Math.round((this.goldRatePerGram * 22 / 24) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    DisplayTWENTY_ONE_KARAT () {
      return parseFloat(Math.round((this.goldRatePerGram * 21 / 24) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    DisplayEIGHTEEN_KARAT () {
      return parseFloat(Math.round((this.goldRatePerGram * 18 / 24) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    DisplayFOURTEEN_KARAT () {
      return parseFloat(Math.round((this.goldRatePerGram * 14 / 24) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    DisplayONE_OUNCE_KRUGERRAND () {
      return parseFloat(Math.round(this.goldRatePerOunce * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      // return this.goldRatePerOunce
    },
    DisplayHALF_OUNCE_KRUGERRAND () {
      return parseFloat(Math.round((this.goldRatePerOunce / 2) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    DisplayQUARTER_OUNCE_KRUGERRAND () {
      return parseFloat(Math.round((this.goldRatePerOunce / 4) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    DisplayONE_TENTH_OUNCE_KRUGERRAND () {
      return parseFloat(Math.round((this.goldRatePerOunce / 10) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    proportions () {
      if (Object.keys(this.commodity.type).length === 0) return []
      if (this.commodity.type.value === 'SILVER_JEWELLERY' || this.commodity.type.value === 'OTHER_SILVER_COINS' || this.commodity.type.value === 'OTHER_GOLD_COINS') return []
      return this.proportionTypes.filter(item => item.commodityTypes.includes(this.commodity.type.value))
    },
    calculatedAmount () {
      if (Object.keys(this.commodity.type).length === 0 && (!this.commodity.weightInGrams || !this.commodity.quantity)) {
        return parseFloat(Math.round((0) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
      switch (this.commodity.type.value) {
        case 'GOLD_KRUGERRAND_COIN':
          if (this.commodity.proportion) {
            if (Object.keys(this.commodity.proportion).length === 0) {
              return 0
            } else {
              return (this.commodity.quantity * this[this.commodity.proportion.value]).toFixed(2)
            }
          } else {
            return 0
          }
        case 'GOLD_JEWELLERY':
          if (Object.keys(this.commodity.proportion).length === 0) {
            return 0
          } else {
            return (this.commodity.weightInGrams * this[this.commodity.proportion.value]).toFixed(2)
          }
        case 'SILVER_JEWELLERY':
          return (this.commodity.weightInGrams * this.silverRatePerGram).toFixed(2)
        case 'OTHER_SILVER_COINS':
          return (this.commodity.weightInGrams * this.silverRatePerGram).toFixed(2)
        case 'OTHER_GOLD_COINS':
          return (this.commodity.weightInGrams * this.TWENTY_TWO_KARAT).toFixed(2)
      }
      return 0
    }
  },
  methods: {
    ...mapActions([
      'addToCommodities',
      'editCommodities',
      'deleteCommoditiesItem',
      'clearCommodities'
    ]),
    displayDescription (val) {
      if (typeof val === 'string' || val instanceof String) {
        const splitItem = val.split('_')
        const arr = []
        splitItem.forEach(item => {
          const lowercase = item.toLowerCase()
          const caps = lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
          arr.push(caps)
        })
        const str = arr.slice(0, arr.length).join(' ')
        return str
      }
    },
    showDelete (key, val) {
      if (val.description) {
        this.commodity.description = val.description
      } else {
        this.commodity.description = ''
      }
      if (val.type) {
        this.commodity.type = val.type
      } else {
        this.commodity.type = {}
      }
      if (val.quantity) {
        this.commodity.quantity = val.quantity
      } else {
        this.commodity.quantity = null
      }
      if (val.weightInGrams) {
        this.commodity.weightInGrams = val.weightInGrams
      } else {
        this.commodity.weightInGrams = null
      }
      if (val.proportion) {
        this.commodity.proportion = val.proportion
      } else {
        this.commodity.proportion = {}
      }
      if (val.commodityTypes) {
        this.commodity.commodityTypes = val.commodityTypes
      } else {
        this.commodity.commodityTypes = ''
      }
      if (val.total) {
        this.commodity.total = val.total
      } else {
        this.commodity.total = 0
      }
      this.currentKey = key
      this.isDelete = true
    },
    closeDelete  () {
      this.commodity = {
        description: '',
        type: {},
        quantity: null,
        weightInGrams: null,
        proportion: {},
        total: 0
      }
      this.currentKey = null
      this.isDelete = false
    },
    deleteItem () {
      this.deleteCommoditiesItem(this.currentKey)
      this.commodity = {
        description: '',
        type: {},
        quantity: null,
        weightInGrams: null,
        proportion: {},
        total: 0
      }
      this.currentKey = null
      this.isDelete = false
    },
    cancel () {
      this.commodity = {
        description: '',
        type: {},
        quantity: null,
        weightInGrams: null,
        proportion: {},
        total: 0
      }
      this.isEdit = false
      this.currentKey = null
    },
    editIndividual (key, val) {
      this.isEdit = true
      this.currentKey = key

      this.commodityTypes.forEach((item) => {
        if (item.value === val.commodityType) {
          this.commodity.type = item
        }
      })

      this.proportionTypes.forEach((item) => {
        if (item.value === val.proportion) {
          this.commodity.proportion = item
        }
      })
      this.commodity.description = val.description
      this.commodity.total = val.amount
      this.commodity.quantity = val.quantity
      this.commodity.weightInGrams = val.weightInGrams
    },
    async update () {
      const saveCommodity = {
        key: '',
        item: {
          description: '',
          quantity: 0,
          weightInGrams: 0,
          amount: 0,
          proportion: '',
          commodityType: ''
        }
      }
      this.commodity.total = parseFloat(this.calculatedAmount)
      this.commodity.commodityTypes = this.commodity.type.value

      saveCommodity.key = this.currentKey
      saveCommodity.item.description = this.commodity.description
      saveCommodity.item.quantity = this.commodity.quantity
      saveCommodity.item.weightInGrams = this.commodity.weightInGrams
      saveCommodity.item.amount = this.commodity.total
      saveCommodity.item.proportion = this.commodity.proportion.value

      saveCommodity.item.commodityType = this.commodity.commodityTypes
      this.editCommodities(saveCommodity)
      this.commodity = {
        description: '',
        type: {},
        quantity: null,
        weightInGrams: null,
        proportion: {},
        commodityTypes: '',
        total: 0
      }
      this.isEdit = false
      this.currentKey = null
    },
    async save () {
      this.commodity.total = parseFloat(this.calculatedAmount)
      this.commodity.commodityTypes = this.commodity.type.value

      const saveCommodity = {
        description: '',
        quantity: 0,
        weightInGrams: 0,
        amount: 0,
        proportion: '',
        commodityType: ''
      }
      saveCommodity.description = this.commodity.description
      saveCommodity.quantity = this.commodity.quantity
      saveCommodity.weightInGrams = this.commodity.weightInGrams
      saveCommodity.amount = this.commodity.total
      saveCommodity.proportion = this.commodity.proportion.value
      saveCommodity.commodityType = this.commodity.commodityTypes
      this.addToCommodities(saveCommodity)
      this.commodity = {
        description: '',
        type: {},
        quantity: null,
        weightInGrams: null,
        proportion: {},
        commodityTypes: '',
        total: 0
      }
    }
  }
}
</script>

<style scoped>
.smallheading {
  color: var(--green-color);
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
